if (process.env.NEXT_PUBLIC_ANALYTICS_DISABLED === "true") {
  Object.assign(globalThis, {
    fbq: () => {},
  });
}

export function pageview(url: string, params: Record<string, any> = {}) {
  // eslint-disable-next-line no-param-reassign
  params.pageType ??= url.split("/")[1];

  window.fbq("track", "PageView", params);
}

export function event(action: string, params: facebook.Pixel.CustomParameters) {
  window.fbq("track", action, params);
}
