if (process.env.NEXT_PUBLIC_ANALYTICS_DISABLED === "true") {
  Object.assign(globalThis, {
    gtag: () => {},
  });
}

/**
 * GTAG Universal Analytics will fire once automatically on first pageview.
 * Track this flag to avoid doubling up on the initial pageview.
 */
let hasFiredBefore = false;

export function pageview(url: string) {
  if (!hasFiredBefore) {
    hasFiredBefore = true;
    return;
  }

  // @ts-ignore broken types
  window.gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID, {
    page_path: url,
  });
}

export function event(
  action: Gtag.EventNames | string,
  params?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams
) {
  window.gtag("event", action, params);
}
