import "@releaseit/ui/styles/global.css";

import dynamic from "next/dynamic";
import Head from "next/head";

import { Seo } from "@/components";
import { AnalyticsScriptTags, startTrackingPageviews } from "@/utils/analytics";
import { AppPropsWithLayout } from "@/types/next";

const DefaultLayout = dynamic(() => import("@/components/layout/DefaultLayout"));
const Providers = dynamic(() => import("@/lib/Providers"));

startTrackingPageviews();

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page: React.ReactNode) => page);
  const pageWithNestedLayout = getLayout(<Component {...pageProps} />);

  return (
    <>
      <Head>
        <link key="api-preconnect" rel="preconnect" href={process.env.NEXT_PUBLIC_API_URL} />
        <link
          rel="preload"
          href="/fonts/Aeonik-Regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/Aeonik-Medium.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      </Head>

      <Seo />
      <AnalyticsScriptTags />

      <Providers pageProps={pageProps}>
        <DefaultLayout>{pageWithNestedLayout}</DefaultLayout>
      </Providers>
    </>
  );
}

export default MyApp;
