/* eslint-disable react/destructuring-assignment */

import Head from "next/head";
import { useRouter } from "next/router";

const DEFAULT_META = {
  title: "Releaseit AU",
  siteName: "Releaseit AU",
  description:
    "The sustainable rental marketplace. Find thousands of products to rent across fashion, party & events, campervans, baby & kids, exercise equipment and more!",
  url: "https://releaseit.com.au",
  type: "website",
  robots: "follow, index",
  image: "https://releaseit.com.au/default-og-image.jpg",
};

type SeoProps = Partial<typeof DEFAULT_META> & {
  date?: string;
  templateTitle?: string;
  canonical?: string;
  children?: React.ReactNode;
};

export function Seo(props: SeoProps) {
  const router = useRouter();

  const meta = {
    ...DEFAULT_META,
    ...props,
  };

  meta.title = props.templateTitle ? `${props.templateTitle} | ${meta.siteName}` : meta.title;
  meta.canonical ??= `${meta.url}${router.asPath}`;

  return (
    <Head>
      <title>{meta.title}</title>
      <link key="canonical" rel="canonical" href={meta.canonical} />
      <meta key="robots" name="robots" content={meta.robots} />
      <meta key="description" name="description" content={meta.description} />

      {/* Open Graph */}
      <meta key="og:url" property="og:url" content={meta.canonical} />
      <meta key="og:type" property="og:type" content={meta.type} />
      <meta key="og:site_name" property="og:site_name" content={meta.siteName} />
      <meta key="og:title" property="og:title" content={meta.templateTitle ?? meta.title} />
      <meta key="og:description" property="og:description" content={meta.description} />
      <meta key="og:image" property="og:image" name="image" content={meta.image} />

      {/* Twitter */}
      <meta key="twitter:card" name="twitter:card" content="summary_large_image" />
      <meta key="twitter:site" name="twitter:site" content="@releaseitau" />
      <meta key="twitter:title" name="twitter:title" content={meta.templateTitle ?? meta.title} />
      <meta key="twitter:description" name="twitter:description" content={meta.description} />
      <meta key="twitter:image" name="twitter:image" content={meta.image} />

      {/* Favicons */}
      {FAVICONS.map((linkProps) => (
        <link key={linkProps.href} {...linkProps} />
      ))}

      {props.children}
    </Head>
  );
}

const FAVICONS = [
  {
    rel: "apple-touch-icon",
    sizes: "57x57",
    href: "/favicon/apple-icon-57x57.png",
  },
  {
    rel: "apple-touch-icon",
    sizes: "60x60",
    href: "/favicon/apple-icon-60x60.png",
  },
  {
    rel: "apple-touch-icon",
    sizes: "72x72",
    href: "/favicon/apple-icon-72x72.png",
  },
  {
    rel: "apple-touch-icon",
    sizes: "76x76",
    href: "/favicon/apple-icon-76x76.png",
  },
  {
    rel: "apple-touch-icon",
    sizes: "114x114",
    href: "/favicon/apple-icon-114x114.png",
  },
  {
    rel: "apple-touch-icon",
    sizes: "120x120",
    href: "/favicon/apple-icon-120x120.png",
  },
  {
    rel: "apple-touch-icon",
    sizes: "144x144",
    href: "/favicon/apple-icon-144x144.png",
  },
  {
    rel: "apple-touch-icon",
    sizes: "152x152",
    href: "/favicon/apple-icon-152x152.png",
  },
  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    href: "/favicon/apple-icon-180x180.png",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "192x192",
    href: "/favicon/android-icon-192x192.png",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "32x32",
    href: "/favicon/favicon-32x32.png",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "96x96",
    href: "/favicon/favicon-96x96.png",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "16x16",
    href: "/favicon/favicon-16x16.png",
  },
  {
    rel: "manifest",
    href: "/favicon/manifest.json",
  },
];
