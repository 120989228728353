import Router from "next/router";
import Script from "next/script";

import * as cio from "@/lib/cio";
import * as fbp from "@/lib/fbp";
import * as ga from "@/lib/ga";
import * as taboola from "@/lib/taboola";

const ANALYTICS_DISABLED = process.env.NEXT_PUBLIC_ANALYTICS_DISABLED === "true";

type IdentifyUserInput = { id: string; email?: string } | { id?: string; email: string };

function identifyUser(user: IdentifyUserInput) {
  cio.identify(user);
}

function trackEvent(action: string, params: Record<string, any>) {
  cio.event(action, params);
  ga.event(action, params);
  fbp.event(action, params);
  // taboola.event(action, params);
}

export const analytics = {
  /**
   * The individual analytics providers that can be used for idenpendent tracking.
   */
  providers: {
    cio,
    fbp,
    ga,
  },

  identifyUser,
  trackEvent,
};

/**
 * Pageview tracking.
 */

export function startTrackingPageviews() {
  if (ANALYTICS_DISABLED) {
    return;
  }

  // Don't try to track on server.
  if (typeof window === "undefined") {
    return;
  }

  Router.events.on("routeChangeComplete", (url) => {
    cio.pageview(url);
    ga.pageview(url);
    fbp.pageview(url);
    taboola.pageview(url);
  });
}

/**
 * Analytics Tags
 */

export function AnalyticsScriptTags() {
  if (ANALYTICS_DISABLED) {
    return null;
  }

  return (
    <>
      {/* Google Analytics */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${
          process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID?.split(",")[0]
        }`}
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          ${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID?.split(",")
            .map((id) => `gtag('config', '${id}');`)
            .join("\n")}
        `}
      </Script>

      {/* Customer IO */}
      <Script id="cio-analytics" strategy="afterInteractive">
        {`
          var _cio = _cio || [];
          (function() {
              var a,b,c;a=function(f){return function(){_cio.push([f].
              concat(Array.prototype.slice.call(arguments,0)))}};b=["load","identify",
              "sidentify","track","page"];for(c=0;c<b.length;c++){_cio[b[c]]=a(b[c])};
              var t = document.createElement('script'),
                  s = document.getElementsByTagName('script')[0];
              t.async = true;
              t.id    = 'cio-tracker';
              t.setAttribute('data-site-id', '${process.env.NEXT_PUBLIC_CUSTOMER_IO_ID}');
              t.setAttribute('data-use-array-params', 'true');
              t.src = 'https://assets.customer.io/assets/track.js';
              s.parentNode.insertBefore(t, s);
          })();
        `}
      </Script>

      {/* Facebook Pixel */}
      <Script id="fb-pixel" strategy="afterInteractive">
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}');
          fbq('track', 'PageView');
        `}
      </Script>
      <noscript>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src={`https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1`}
          alt=""
        />
      </noscript>

      {/* Taboola */}
      <Script id="taboola-pixel" strategy="afterInteractive">
        {`
          window._tfa = window._tfa || [];
          window._tfa.push({ notify: "event", name: "page_view", id: ${process.env.NEXT_PUBLIC_TABOOLA_ID} });
          !(function (t, f, a, x) {
            if (!document.getElementById(x)) {
              t.async = 1;
              t.src = a;
              t.id = x;
              f.parentNode.insertBefore(t, f);
            }
          })(
            document.createElement("script"),
            document.getElementsByTagName("script")[0],
            "//cdn.taboola.com/libtrc/unip/${process.env.NEXT_PUBLIC_TABOOLA_ID}/tfa.js",
            "tb_tfa_script"
          );
        `}
      </Script>

      {/* Teads */}
      <Script id="teads-pixel" strategy="afterInteractive">
        {`
          window.teads_e = window.teads_e || [];
          window.teads_buyer_pixel_id = ${process.env.NEXT_PUBLIC_TEADS_ID};
        `}
      </Script>
      <Script strategy="afterInteractive" src="https://p.teads.tv/teads-fellow.js" />
    </>
  );
}
