declare global {
  interface Window {
    _tfa: any;
  }
}

if (process.env.NEXT_PUBLIC_ANALYTICS_DISABLED === "true") {
  Object.assign(globalThis, {
    _tfa: {
      push: () => {},
    },
  });
}

/**
 * Taboola tag will fire once automatically on first pageview.
 * Track this flag to avoid doubling up on the initial pageview.
 */
let hasFiredBefore = false;

export function pageview(url: string, params: Record<string, any> = {}) {
  if (!hasFiredBefore) {
    hasFiredBefore = true;
    return;
  }

  // eslint-disable-next-line no-param-reassign
  params.pageType ??= url.split("/")[1];

  window._tfa.push({ notify: "event", name: "page_view", ...params });
}

export function event(action: string, params: Record<string, any>) {
  window._tfa.push({ notify: "event", name: action, ...params });
}
